import React, {useEffect, useState} from 'react';

import './App.css';
import './styles/header.css'
import './styles/product.scss'
import './styles/product.scss'
import './styles/youtube.scss'
import Logo from './components/header/Logo';
import {Carousel, Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import Tractors from './components/body/Tractors';
import Footer from './components/footer/Footer';
import LeasingOffer from './components/LeasingOffer';
import Payments from "./components/body/Payments";
import Delivery from "./components/body/Delivery";
import YouTubeItem from "./components/YouTubeItem";
import axios from "axios";

function App() {
  const [data, setData] = useState<{ CODE: string }[]>([]);
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);

  function jivo_onLoadCallback() {
    console.log('Widget fully loaded');
    // jivo_api.showProactiveInvitation("How can I help you?");
  }

  const getYoutube = async () => {
    const rs = await axios.post('https://farmtrac-russia.ru/local/ajax/youtube.php')
    setData(rs.data);
  }

  useEffect(() => {
    getYoutube();
  }, []);


  return (
    <div className="App">
      <Navbar bg={'light'} expand={'lg'} sticky={'top'}>
        <Container fluid>
          <Navbar.Brand>
            <Logo/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#catalog">Каталог</Nav.Link>
              <Nav.Link href="#pay">Оплата</Nav.Link>
              <Nav.Link href="#leasing">Лизинг</Nav.Link>
              <Nav.Link href="#delivery">Доставка</Nav.Link>
              <Nav.Link href="#media">Медиа</Nav.Link>
              <Nav.Link href="#contacts">Контакты</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Text><a href="tel:84951289638">8 (495) 128-96-38</a></Navbar.Text>
        </Container>
      </Navbar>

      <Container>
        <h1 className='pt-5 mb-4'>Официальный Дистрибьютор минитракторов Farmtrac в России</h1>
      </Container>

      <Container className={'app-box'}>
        <Tractors/>
      </Container>
      <Payments/>

      <Container className={'app-box'}>
        <LeasingOffer/>
      </Container>

      <Delivery/>

      <Container className={'app-box'} id={'media'}>
        <h2>Наш YouTube</h2>
        <Row>
          {data ? data.map((item) => <YouTubeItem id={item?.CODE}/>) : undefined}
        </Row>

      </Container>
      <Footer/>

    </div>
  );
}

export default App;
