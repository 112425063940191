import React from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";

const Delivery = () => {

  return <Container className={'app-box'} id={'delivery'}>
    <h2>Доставка</h2>
    <Row>
      <Col xs={12} className={'mb-1'}>
        <Card>
          <Card.Body>
            <Card.Title>Доставка транспортной компанией</Card.Title>
            <Card.Text>
              Мы работаем с ведущими транспортными компаниями РФ, поэтому какой именно вам будет удобнее воспользоваться
              решать только вам. Для расчета стоимости доставки лично менеджеру, оформившему заказ. Наши операторы
              всегда будут рады помочь вам.

              До терминала ТК в Москве доставка бесплатная. Далее оплачиваете доставку согласно тарифу выбранной
              транспортной компании.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>

}
export default Delivery;