import React from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";

const Payments = () => {

  return <Container className={'app-box'} id={'pay'}>
    <h2>Способы оплаты</h2>
    <Row>
      <Col xs={12} className={'mb-1'}>
        <Card>
          <Card.Body>
            <Card.Title>Банковский перевод</Card.Title>
            <Card.Text>
              Безналичное перечисление денежных средств с расчётного счета Заказчика (любого банка) на счет ООО
              «ЛР-Авто»,
              по реквизитам указанным в счете.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} className={'mb-1'}>
        <Card>
          <Card.Body>
            <Card.Title>Наличный расчёт при получении товара</Card.Title>
            <Card.Text>
              О возможности оплаты наличными при получении товара уточняйте у менеджера.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} className={'mb-1'}>
        <Card>
          <Card.Body>
            <Card.Title>Наличный расчёт в магазине или офисе компании</Card.Title>
            <Card.Text>
              За интересующий вас товар можно оплатить непосредственно в точке продаж или в офисе. В основном
              необязательно платить всю сумму, можно оставить предоплату, а оставшуюся часть оплатить на месте
              получения.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>

}
export default Payments;