import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', padding: '20px 0' }} id={'contacts'}>
      <Container>
        <Row>
          <Col md={6}>
            <h4>Информация о нас</h4>
            <p>Организация: ООО ЛР-Авто</p>
            <p>ИНН 7720670322 КПП 772001001</p>
            <p>Адрес: г. Москва, МКАД, 86-й километр, вл. 13 с. 1А</p>
            <p>Телефон: <a href="tel:84951289638"> 8 (495) 128-96-38</a></p>
            <p>E-mail: <a href="mailto:farmtracrussia.ru@gmail.com" >farmtracrussia.ru@gmail.com</a></p>
            <p>Мы открыты: пн-пт: с 9:00 до 23:00</p>
            <div>
              <a href={'https://t.me/farmtracrussia'} target={'_blank'} rel={"nofollow noreferrer"} ><FontAwesomeIcon icon={faTelegram} color={'#08c'} size={'2x'}/></a>
              <a href={'https://www.youtube.com/@FARMTRACRUSSIA'} target={'_blank'} rel={"nofollow noreferrer"} ><FontAwesomeIcon icon={faYoutube} color={'red'} size={'2x'}/></a>

            </div>

          </Col>

          <Col xs={12} md={6}>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A3cdc99c54a15c6f6b30733d58c78f61b1070f29b95f153787ad1d2ee6e4b1ca0&amp;source=constructor"
              width="100%" height="346" frameBorder="0"></iframe>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;