import React, {useState} from 'react';
import {Button, Modal, Form, Row} from 'react-bootstrap';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// @ts-ignore
import InputMask from 'react-input-mask';


const ProductRequestForm = ({product}: { product: string }) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    axios.post('https://farmtrac-russia.ru/local/ajax/request.php', {
      name, email,phone, message, product
    }, {
      withCredentials: false
    }).then(rs => {
      // @ts-ignore
      ym(95192633,'reachGoal','SendForm')
    })
    // Закрытие модального окна
    setShowModal(false);
  };

  return (
    <div>
      <Button size={'sm'} onClick={() => setShowModal((prevState) => {
        // @ts-ignore
        ym(95192633,'reachGoal','OpenForm');
        return true
      })}>Отправить заявку</Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Получить коммерческое предложение</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <ReCAPTCHA sitekey={'6LeApKIoAAAAANcDgsJU-kQ2vpkI3_pp9B24_UzS'} size={'invisible'}/>
            <Form.Group controlId="formName">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ваше имя"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Введите ваш e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Телефон</Form.Label>
              <InputMask className={'form-control'} mask={'+79999999999'} placeholder="Введите ваш телефон"
                         value={phone}
                         required
                         onChange={(e:any) => setPhone(e.target.value)}/>

            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Сообщение</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Введите ваше сообщение"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Button variant="primary" type="submit">
                Отправить
              </Button>
              <div>Нажимая отправить я даю свое согласие на обработку персональных данных</div>

            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductRequestForm;
