import React, {useState} from 'react';
import {Button, Form, Modal, Row} from "react-bootstrap";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

import InputMask from "react-input-mask";
import axios from "axios";

const LeasingOffer = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');

    const handleContactSubmit = async (e: any) => {
        e.preventDefault();
        axios.post('https://farmtrac-russia.ru/local/ajax/request.php', {
            name, email,phone, message, product: 'Лизинг'
        }, {
            withCredentials: false
        }).then(rs => {
            // @ts-ignore
            ym(95192633,'reachGoal','SendForm')
        })
        // Закрытие модального окна
        setShow(false);
    };

    const handleOpenForm = () => {
        // @ts-ignore
        ym(95192633,'reachGoal','OpenForm');
        setShow(true);
    }

    return (
        <div id={'leasing'}>
            <h2>Хотите приобрести тракторы Farmtrac и навесное оборудование, но не хотите тратить большие суммы сразу?</h2>
            <p>Мы предлагаем выгодные условия лизинга для приобретения техники для сельского хозяйства. Получите доступ к широкому выбору моделей и комплектующих с гарантированным качеством и надежностью. Заключите выгодный лизинговый договор прямо сейчас и получите удобные условия доставки и сервисного обслуживания. Обновите свою технику уже сегодня без больших финансовых затрат!</p>
            <Button onClick={() => handleOpenForm()}>Оставить заявку на лизинг</Button>

            <Modal show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleContactSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Запрос консультации по лизингу</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <ReCAPTCHA sitekey={'6LeApKIoAAAAANcDgsJU-kQ2vpkI3_pp9B24_UzS'} size={'invisible'}/>
                        <Form.Group controlId="formName">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Введите ваше имя"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Введите ваш e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhone">
                            <Form.Label>Телефон</Form.Label>
                            <InputMask className={'form-control'} mask={'+79999999999'} placeholder="Введите ваш телефон"
                                       value={phone}
                                       required
                                       onChange={(e:any) => setPhone(e.target.value)}/>

                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label>Сообщение</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Введите ваше сообщение"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Button variant="primary" type="submit">
                                Отправить заявку
                            </Button>
                            <div>Нажимая отправить я даю свое согласие на обработку персональных данных</div>

                        </Row>

                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

export default LeasingOffer;