import axios from "axios";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Tractor from "./Tractor";
import TractorCard from "./TractorCard";

export interface ProductType {
  name: string;
  previewPic?: string;
  additionalImages?: Array<any>;
  price?: string;
  params?: {
    area: string;
    country: string;
    warranty: string;
    length: string;
    weight: string;
    height: string;
    width: string;
    wheelbase: string;
    clearance: string;
    maxSpeed: string;
    radius: string;
  };
  engine: {
    model: string;
    type: string;
    volume: string;
    manufacturer: string
    power: string,
    cylinder: string;
    rotationSpeed: string;
    coldSystem: string;
    generator: string;
    battery: string;
  },
  transmission: {
    transmission: string;
    transmissionManage: string;
    transmissionBox: string;
    driveUnit: string;
    cntSpeed: string;
    differintial: string;
    stop: string;
    clutch: string;
  },
  other: {
    bom: string;
    perfomance: string;
    hitchCategory: string;
    capacity: string;
  }
}

const Tractors = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const rs = await axios.post('https://farmtrac-russia.ru/local/ajax/catalog.php')
    setData(rs.data);
  }

  useEffect(() => {
    getData();
  }, []);


  return <div id={'catalog'}>
    {Object.keys(data) ? Object.entries(data).map(([key, value]: [string, ProductType]) => {
      //   return <Tractor
      //     key={key}
      //     title={value?.name}
      //     engine={value?.engine}
      //     params={value?.params}
      //     price={value?.price}
      //     mainImage={value.previewPic}
      //     name={value?.name}
      //     other={value?.other}
      //     additionalImages={value?.additionalImages}
      //     transmission={value?.transmission}/>
      // }) : undefined}
      return <TractorCard
        key={key}
        title={value?.name}
        engine={value?.engine}
        params={value?.params}
        price={value?.price}
        mainImage={value.previewPic}
        name={value?.name}
        other={value?.other}
        additionalImages={value?.additionalImages}
        transmission={value?.transmission}/>
    }) : undefined}
  </div>
}

export default Tractors;