import React, {useEffect, useState} from 'react';
import {Button, Card, Carousel, Col, Form, Image, ListGroup, Modal, Row, Table} from 'react-bootstrap';
import RequestForm from "../RequestForm";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import {ProductType} from "./Tractors";
import ym from 'react-yandex-metrika';

interface Props extends ProductType {
  title: string;
  description?: string;
  mainImage?: string;
}

const TractorCard = (
  {
    title,
    description,
    mainImage,
    additionalImages,
    price,
    params,
    engine,
    transmission,
    other
  }: Props) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (show) {
      // @ts-ignore
      // ym(95192633,'reachGoal','SendForm')
    }
  }, [show])

  return (
    <Row className={'mb-2 product'}  >
      <Col className={'product-img'} xs={12} md={3} onClick={() => setShow(true)}>
        <img width={'100%'} src={mainImage}/>
      </Col>
      <Col md={6}>
        <div className={'product-name'} onClick={() => setShow(true)}>{title}</div>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>Вес: {params?.weight}</ListGroup.Item>
          <ListGroup.Item>Мощность двигателя, л/с: {engine?.power}</ListGroup.Item>
          <ListGroup.Item>Производитель двигателя: {engine?.manufacturer}</ListGroup.Item>
          <ListGroup.Item>Обьем двигателя: {engine?.volume}</ListGroup.Item>
        </ListGroup>

      </Col>
      <Col md={3}>
        <Row>
          <Col><div className={'product-price'}>{price} ₽</div></Col>
        </Row>
        <Row>
          <Col><RequestForm product={title}/></Col>
        </Row>
      </Col>



      <Modal show={show} onHide={() => setShow(false)} size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={5}>
              <Carousel>
                {Array.isArray(additionalImages) ? additionalImages.map((image, index) => (
                  <Carousel.Item key={index}>
                    <div>
                      <img className="d-block w-100" src={image} alt={`Additional Image ${index}`}/>
                    </div>


                  </Carousel.Item>
                )) : undefined}
              </Carousel>
            </Col>
            <Col className={'product-modal'} md={6}>
              <Table>
                <tbody>
                <tr>
                  <th colSpan={2}>Общие характеристики</th>
                </tr>
                <tr>
                  <td>Область применения</td>
                  <td>{params?.area}</td>
                </tr>
                <tr>
                  <td>Страна производстава</td>
                  <td>{params?.country}</td>
                </tr>
                <tr>
                  <td>Гарантия, мес</td>
                  <td>{params?.warranty}</td>
                </tr>
                <tr>
                  <td>Длина, мм</td>
                  <td>{params?.length}</td>
                </tr>
                <tr>
                  <td>Ширина, мм</td>
                  <td>{params?.width}</td>
                </tr>
                <tr>
                  <td>Высота, мм</td>
                  <td>{params?.height}</td>
                </tr>
                <tr>
                  <td>Вес, кг</td>
                  <td>{params?.weight}</td>
                </tr>
                <tr>
                  <td>Колесная база</td>
                  <td>{params?.wheelbase}</td>
                </tr>
                <tr>
                  <td>Макс. скорость, км/час</td>
                  <td>{params?.maxSpeed}</td>
                </tr>
                <tr>
                  <td>Радиус разворота, см</td>
                  <td>{params?.radius}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Двигатель</th>
                </tr>
                <tr>
                  <td>Производитель</td>
                  <td>{engine?.manufacturer}</td>
                </tr>

                <tr>
                  <td>Модель</td>
                  <td>{engine?.model}</td>
                </tr>

                <tr>
                  <td>Тип</td>
                  <td>{engine?.type}</td>
                </tr>
                <tr>
                  <td>Мощность, л/с</td>
                  <td>{engine?.power}</td>
                </tr>
                <tr>
                  <td>Обьем, см3</td>
                  <td>{engine?.volume}</td>
                </tr>
                <tr>
                  <td>Число цилиндров</td>
                  <td>{engine?.cylinder}</td>
                </tr>
                <tr>
                  <td>Номинальная частота вращения, об/мин</td>
                  <td>{engine?.rotationSpeed}</td>
                </tr>
                <tr>
                  <td>Система охлаждения</td>
                  <td>{engine?.coldSystem}</td>
                </tr>
                <tr>
                  <td>Генератор</td>
                  <td>{engine?.generator}</td>
                </tr>
                <tr>
                  <td>Аккумулятор</td>
                  <td>{engine?.battery}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Трансмиссия</th>
                </tr>
                <tr>
                  <td>Трансмиссия</td>
                  <td>{transmission?.transmission}</td>
                </tr>
                <tr>
                  <td>Управление трансмиссией</td>
                  <td>{transmission?.transmissionManage}</td>
                </tr>
                <tr>
                  <td>Привод</td>
                  <td>{transmission?.driveUnit}</td>
                </tr>
                <tr>
                  <td>Коробка передач</td>
                  <td>{transmission?.transmissionBox}</td>
                </tr>
                <tr>
                  <td>Число скоростей</td>
                  <td>{transmission?.cntSpeed}</td>
                </tr>
                <tr>
                  <td>Блокировка заднего дифференциала</td>
                  <td>{transmission?.differintial}</td>
                </tr>
                <tr>
                  <td>Стояночный тормоз</td>
                  <td>{transmission?.stop}</td>
                </tr>
                <tr>
                  <td>Сцепление</td>
                  <td>{transmission?.clutch}</td>
                </tr>
                <tr>
                  <th colSpan={2}>Прочие</th>
                </tr>
                <tr>
                  <td>Задний ВОМ</td>
                  <td>{other?.bom}</td>
                </tr>
                <tr>
                  <td>Производительность, л/мин</td>
                  <td>{other?.perfomance}</td>
                </tr>
                <tr>
                  <td>Категория сцепки</td>
                  <td>{other?.hitchCategory}</td>
                </tr>
                <tr>
                  <td>Грузоподьемность, кг</td>
                  <td>{other?.capacity}</td>
                </tr>

                </tbody>
              </Table>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <div>{price} руб.</div>
            </Col>
            <Col> <RequestForm product={title}/></Col>
          </Row>
        </Modal.Footer>
      </Modal>

      {/*<Card style={{margin: 'auto'}}>
        <Card.Img variant="top" src={mainImage}/>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            <div>{description}</div>
            {price ? <div>{price} руб.</div> : undefined}
          </Card.Text>

          <Carousel>
            {Array.isArray(additionalImages) ? additionalImages.map((image, index) => (
              <Carousel.Item key={index}>
                <div>
                  <img className="d-block w-100" src={image} alt={`Additional Image ${index}`}/>
                </div>


              </Carousel.Item>
            )) : undefined}
          </Carousel>
        </Card.Body>
      </Card>*/}
    </Row>
  );
};

export default TractorCard;