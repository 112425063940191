import React, {useEffect} from "react";
import {Col} from "react-bootstrap";

export default ({id}: { id: string }) => {

  useEffect(() => {
    const youtube = document.querySelector(`.youtube[data-embed="${id}"]`)
    // console.log(youtube)
    const source = "https://img.youtube.com/vi/" + id + "/sddefault.jpg";
    const image = new Image();
    image.src = source;
    if (youtube) {
      youtube.appendChild(image);
    }
  }, [])

  window.addEventListener('load', () => {


  })

  const onloadIframe = (el: any) => {
    const iframe = document.createElement("iframe");
    const youtube = document.querySelector(`.youtube[data-embed="${id}"]`)

    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", `https://www.youtube.com/embed/${id}?rel=0&showinfo=0&autoplay=1`);

    if (youtube) {
      youtube.innerHTML = "";
      youtube.appendChild(iframe);
    }
  }

  return (
    <Col md={4}>
      <div className="youtube" data-embed={id} onClick={(el) => onloadIframe(el)}>
        <div className="play-button"></div>
      </div>
    </Col>
  );
};